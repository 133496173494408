import { gql } from '@apollo/client';
import {
  sharedInvoiceInput,
  sharedInvoiceInputFields,
} from '../fragments/invoice-input';

const AVAILABLE_FACTORING = gql`
  query availableFactoring(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      totalCount
      edges {
        cursor
        node {
          id
          folio
          dateIssued
          siiStatus
          loanedStatus
          loanedToRut
          loanedTo
          creditNoteStatus
          isForOrdering
          hasPaymentsEmitted
          amountWithIva {
            amount
            currency {
              code
              name
            }
          }
          dteType {
            id
            name
          }
          receiver {
            id
            name
            displayNationalIdentifier
          }
          preoffer {
            id
            monthlyRate
            defaultRate
            preofferevaluationrequest {
              id
              status
            }
          }
          offer {
            id
          }
          operation {
            id
          }
          references {
            id
            folio
            amountWithIva {
              amount
              currency {
                code
                name
              }
            }
          }
          creditNotes @client {
            id
            folio
            amountWithIva {
              amount
              currency {
                code
                name
              }
            }
          }
          debitNotes @client {
            id
            folio
            amountWithIva {
              amount
              currency {
                code
                name
              }
            }
          }
          preofferSelectable @client
        }
      }
    }
  }
`;

export default AVAILABLE_FACTORING;
