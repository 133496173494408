import { gql } from '@apollo/client';
import { CompanyFields } from '../fragments';

const UPLOAD_CREDENTIALS = gql`
  mutation uploadCredentials(
    $username: String!
    $password: String!
    $companyId: Int
    $credentialProvider: Int!
    $source: String
    $origin: String
  ) {
    uploadCredentials(
      username: $username
      password: $password
      companyId: $companyId
      credentialProvider: $credentialProvider
      source: $source
      origin: $origin
    ) {
      getUser {
        id
        selectedCompany {
          ...CompanyFields
        }
      }
    }
  }
  ${CompanyFields}
`;

export default UPLOAD_CREDENTIALS;
